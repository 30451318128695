import {
	createRouter,
	createWebHistory
} from "vue-router";
// import HomeView from "../views/HomeView.vue";
import Layout from "../layout/layout.vue";

const routes = [{
		path: "/",
		name: "home",
		component: Layout, // 共同布局
		children: [
			// {
			// 	path: "/",
			// 	name: "home",
			// 	component: import("@/views/home/home.vue"),
			// },
			// {
			//   path: "/about",
			//   component: import("@/views/about/about.vue"),
			// },
		],
	},
	{
	  path: "/",
		name: "home",
	  component: import("@/views/home/home.vue"),
	},
	{
		path: "/about",
		name: "about",
		component: import("@/views/about/about.vue"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;