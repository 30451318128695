<template>
	<router-view />
</template>

<style lang="less">
@import '@/assets/font/font.css';

html {
  // font-size: 16px;
}

#app {
	font-family: POPPINS, Roboto, Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}

// 小手
.hand-global {
	cursor: hand;
	cursor: pointer;
}

// 小手不能选中
.hand-user-select-global {
	cursor: hand;
	cursor: pointer;

	-webkit-user-select: none; /*webkit浏览器*/
	-moz-user-select: none; /*火狐*/
	-ms-user-select: none; /*IE10*/
	user-select: none;
}

// 禁止选中
.user-select-global {
	-webkit-user-select: none; /*webkit浏览器*/
	-moz-user-select: none; /*火狐*/
	-ms-user-select: none; /*IE10*/
	user-select: none;
}

// 分割线
.divider-g {
	border-bottom: 1px solid #7c7c7c;
}


// bootstrap 和 ant design 冲突，导致图标移位
body  .anticon {
    transform: translate(-0.5px, -3px);
}

* {
	// font-family: Roboto-Regular, Roboto;
	// font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
