export default {
  t1: "白皮书",
  t2: "世界的每一個機會你都有機會",
  t3: "公開募集",
  t4: "認購地址",
  t5: "請保護好您的付款帳戶，購買節點後項目產生的算力，權益，以及分紅都將自動發放到您的地址",
  t6: "公司簡介",
  t7: "是香港區區塊鏈交易所的生態服務，已與全球各大交易所合作 研發生產線上電商數字貨幣支付系統，線下櫃員機，pos機，商家收款碼，用戶下單核銷系統等。",
  t8: "為全球區塊鏈數字貨幣用戶以及商家提供線上線下收付款，兌換等服務。",
  t9: "區塊鏈數字貨幣將和PayPal以及支付寶一樣便捷使用。發行A&B幣作為流通兌換期間產生的手續費的唯一通證。",
  t10: "萬枚",
  t11: "A&B流通幣發行總量",
  t12: "通過官網IDO51名",
  t13: "進入LP流動性池後鎖死，丟棄權限，開放審計。",
  t14: "五重安全措施，資⾦與數據合規安全",
  t15: "全球監管",

  t16: "以積極的態度擁抱監管，我們已經在世界各地共計57個合規金融服務國家或區域開展業務",
  t17: "擔保保障",
  t18: "為了符合美國各州監管和法律的要求，更為了讓客戶對資金安全放心，我們為客戶資金安全購買了擔保債券 (Money Transmitter Surety Bonds)，由第三方為客戶資金安全提供額外擔保",
  t19: "先進技術",

  p1: "擁有堅實的網路安全架構和資訊保護準則，總部位於以高新技術產業聞名世界的以色列安全技術中心，已通過國際PCI DDS Level 1最高安全級別認證。成立至今的16年來0重大安全事故",
  p2: "採用在國際一流銀行中開立隔離帳戶的方式存放您的資金，接受各國金融監管部門和合作銀行的雙重防護，資金安全無憂，已經在超過145個國家和地區有2個以上的合作支付機構",
  p3: "內外兼修",
  p4: "擁有行業領先的內控專家團隊。確保公司嚴格遵守美國《薩奧法》高標準規定之公司治理、內部控制、財務審計等各方面；聘請了四大作為財務審計，接受全球一流的頭部合作銀行對我們的業務安全和合規審計",
  p5: "與香港區塊鏈交易所深度合作：社群建設方案",
  p6: "開展TABN計劃",
  p7: "本人佈道算力",
  p8: "本人地址當日新增幣量",
  p9: "直接邀請人地址當日新增幣量之和",
  p10: "賬戶安全",

  t20: "直接邀請的所有做市商T值之和",
  t21: "數字貨幣A&B平台規劃",
  t22: "國家覆蓋",
  t23: "生態圈合作夥伴",
  t24: "合規金融服務國家或區域",
  t25: "幣種無障礙對換",
  t26: "構建四大發展壁壘",
  t27: "存儲全鏈數字貨幣",
  t28: "即時兌換法幣",
  t29: "便捷收付款",
  t30: "全球各地線下取款",

  t31: "規劃",
  t32: "自助申請多收款賬號",
  t33: "可以自助申請同一幣種下更多的賬號，用於不同商業目的",
  t34: "eBay對接",
  t35: "完成eBay對接，從開店到註冊收款無縫對接，到賬更迅速，資金管理更有效",
  t36: "隨心付",
  t37: "研發隨心付系列",
  t38: "不用充值費，沒有交易手續費，省去提現費",
  t39: "移動端功能不斷加強",
  t40: "App端可以下載和直接分享帳單，提供對帳單更簡單",
  t41: "App安全設置：包括修改密碼，安全問題，3D安全等",
  t42: "實現",
  t43: "完成生態搭建，為區塊鏈數字貨幣線上線下提供全面收付款服務",
  t44: "最早進入亞太市場的跨境支付企業之一，率先進入B端跨境支付領域。",
  t45: "成為全球唯一一家專註做區塊鏈資金下發的專業金融機構。",
  t46: "率先提出了可持續增長的概念，以各類新型增值服務配合跨境商貿企業進行轉型升級。",
  t47: "計劃成為全球區塊鏈數字貨幣支付第一通證，率先登陸給大交易所。",
  t48: "A&B區塊鏈數字貨幣產品矩陣",
  t49: "環球支付",
  t50: "全球跨境電商收付方案",
  t51: "實體&虛擬卡",
  t52: "多幣種賬戶",
  t53: "移動櫃員機",
  t54: "我們超越期望",
  t55: "我們致力於維護一貫高水準的用戶體驗與服務，並不斷激勵自己想客戶之所求，急客戶之所需，排客戶之所憂。",
  t56: "合規&風控服務",
  t57: "POS機",
  t58: "無障礙兌換",
  t59: "線下收款",
  t60: "我們超越信任",

  t61: "我們重視並奉行誠實正直的品質，持續與內部團隊和客戶保持溝通。我們認為，正是客戶的創業精神成就和激勵了我們。",
  t62: "我們的價值觀",
  t63: "我們超越臆斷",
  t64: "我們認為，正是客戶的創業精神成就和激勵了我們。作為一個跨國公司，我們互相關懷與尊重，擁有強烈的客戶服務意識與責任感。",
  t65: "我們超越關懷",
  t66: "我們以幫助客戶成長與發展為目標，與客戶一起發現和滿足業務需求，並不斷超越期望。",
  t67: "我們超越疆界",
  t68: "我們相信，地域與文化的差異無法剝奪每個人獲得成功的平等機會，也正是得益於多樣性，世界才日益豐富精彩。",
  t69: "服務商合作：連接A&B，連接全球商機",
  t70: "免費直付服務商，對接電商業務系統，享受獨家合作福利。API無縫對接後，即可從用戶的A&B余額中輕鬆扣款，簡單快捷。內置於平臺中，暢享低成本無憂收款體驗。",
  t71: "廣告",
  t72: "本地化&翻譯",
  t73: "檢測服務",
  t74: "物流",
  t75: "軟件",
};
