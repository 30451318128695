export default {
  t1: "Whitepaper",
  t2: "You have a chance at every opportunity in the world",
  t3: "Public Offering",
  t4: "Subscription Address",
  t5: "Please protect your payment account. The computing power, rights, and dividends generated by purchasing nodes will be automatically distributed to your address",
  t6: "Company Profile",
  t7: "It is an ecological service of the Hong Kong blockchain exchange and has cooperated with major global exchanges to develop online e-commerce digital currency payment systems, offline ATMs, POS machines, merchant payment codes, user ordering verification systems, etc.",
  t8: "To provide online and offline payment, exchange, and other services for global blockchain and digital currency users and merchants",
  t9: "Blockchain digital currencies will be as convenient to use as PayPal and Alipay. Issuing A&B coins as the sole token for transaction fees generated during the circulation and exchange period",
  t10: "10,000 coins",
  t11: "Total circulation of A&B coins",
  t12: "Through the official website IDO, there are 51 spots",
  t13: "Locked after entering the LP liquidity pool, abandonment of privileges, and open audits",
  t14: "Five-fold security measures, compliance and security of funds and data",
  t15: "Global regulation",

  t16: "Embracing regulation with a positive attitude, we have expanded our operations in a total of 57 compliant financial service countries or regions worldwide.",
  t17: "Security Guarantee",
  t18: "To comply with the regulatory requirements and laws of various states in the United States and to provide customers with peace of mind regarding fund security, we have purchased Money Transmitter Surety Bonds as an additional guarantee for customer funds, provided by a third party.",
  t19: "Advanced Technology",

  p1: "With a solid network security architecture and information protection guidelines, our headquarters are located in the globally renowned cybersecurity technology center. We have obtained the highest level of security certification, international PCI DDS Level 1. Over the past 16 years, there have been zero major security incidents.",
  p2: "We store your funds using segregated accounts opened in internationally renowned banks, ensuring dual protection from financial regulatory authorities and partner banks in various countries. Your fund security is guaranteed. We have established partnerships with more than 145 countries and regions, with two or more cooperating payment institutions in each.",
  p3: "Balanced Internal and External Development",
  p4: "We have a leading team of internal control experts to ensure strict compliance with the high standards set by the Sarbanes-Oxley Act of the United States in terms of corporate governance, internal control, financial auditing, and more. We engage one of the Big Four for financial auditing and undergo security and compliance audits by top-tier partner banks worldwide.",
  p5: "Deep cooperation with a Hong Kong blockchain exchange: Community building solutions",
  p6: "Launching the TABN program",
  p7: "My own mining power",
  p8: "Daily addition of coins to my address",
  p9: "Sum of daily addition of coins to directly invited addresses",
  p10: "Account Security",

  t20: "Total value (T-value) of all market makers directly invited",
  t21: "Planning for Cryptocurrency A&B Exchanges",
  t22: "National Coverage",
  t23: "Ecosystem Collaboration Partners",
  t24: "Compliant Financial Service Countries or Regions",
  t25: "Seamless Exchange of Currencies",
  t26: "Building Four Major Development Barriers",
  t27: "Storing All-Chain Digital Currencies",
  t28: "Real-time Fiat Currency Conversion",
  t29: "Convenient Payment and Collection",
  t30: "Global Offline Cash Withdrawal",

  t31: "Planning",
  t32: "Apply for Multiple Payment Accounts",
  t33: "You can apply for multiple accounts in the same currency for different business purposes.",
  t34: "eBay Integration",
  t35: "Complete eBay integration, seamlessly connect from store setup to registered payments, faster deposit, more effective fund management.",
  t36: "EasyPay",
  t37: "Develop the EasyPay series",
  t38: "No recharge fees, no transaction fees, and no withdrawal fees.",
  t39: "Enhanced Mobile Features",
  t40: "The app allows you to download and directly share bills, making bill reconciliation easier.",
  t41: "App Security Settings: Including password modification, security questions, 3D security, etc.",
  t42: "Achievements",
  t43: "Complete ecosystem construction, providing comprehensive payment services for online and offline blockchain digital currencies.",
  t44: "One of the earliest cross-border payment companies to enter the Asia-Pacific market, leading the B2B cross-border payment field.",
  t45: "Become the only professional financial institution dedicated to blockchain fund disbursement worldwide.",
  t46: "Pioneered the concept of sustainable growth, coordinating with cross-border trade companies for transformation and upgrading through various new value-added services.",
  t47: "Plan to become the world's first token for blockchain digital currency payments, and be listed on major exchanges.",
  t48: "A&B Blockchain Digital Currency Product Matrix",
  t49: "Global Payment",
  t50: "Global Cross-border E-commerce payment solutions",
  t51: "Physical & Virtual Cards",
  t52: "Multi-currency Accounts",
  t53: "Mobile ATMs",
  t54: "We Go Beyond Expectations",
  t55: "We are dedicated to maintaining consistently high standards of user experience and services, constantly motivating ourselves to meet customer demands, address customer needs, and alleviate customer concerns.",
  t56: "Compliance & Risk Control Services",
  t57: "POS Machine",
  t58: "Accessible Conversion",
  t59: "Offline Payment Collection",
  t60: "We Build Trust Beyond Boundaries",

  t61: "We value and uphold honesty and integrity, maintaining open communication with our internal team and clients. We believe it is the entrepreneurial spirit of our clients that makes us thrive and excel.",
  t62: "Our Values",
  t63: "We Go Beyond Assumptions",
  t64: "We believe it is the entrepreneurial spirit of our clients that makes us thrive and excel. As a multinational company, we care for and respect one another, possessing a strong awareness of customer service and responsibility.",
  t65: "We Go Beyond Care",
  t66: "We aim to help our clients grow and succeed, discovering and fulfilling their business needs together, continuously exceeding expectations.",
  t67: "We Go Beyond Borders",
  t68: "We believe that geographical and cultural differences should not deprive anyone of equal opportunities for success. It is diversity that enriches and makes the world more wonderful every day.",
  t69: "Service Provider Partnership: Linking A&B, Connecting Global Opportunities",
  t70: "As a free direct payment service provider, you can integrate with e-commerce systems and enjoy exclusive partnership benefits. Once seamlessly integrated via API, you can easily deduct payments from users' A&B balances, providing a simple and efficient collection experience. Built into the platform, you can enjoy a low-cost and worry-free collection experience.",
  t71: "Advertising",
  t72: "Localization & Translation",
  t73: "Verification Services",
  t74: "Logistics",
  t75: "Software",
};
